<template>
  <div :class="classes.wrapper">
    <BaseButton
      type="a"
      intent="link-on-color-primary"
      :class="classes.phone"
      :href="getPhoneLink()"
      @click="emitYandexMetrika(YANDEX_METRIKA_GOALS.CONTACT)"
    >
      {{ getPhoneText() }}
    </BaseButton>
    <p :class="classes.time">
      С 10:00 до 20:00
    </p>
    <BaseSocials />
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { getPhoneLink, emitYandexMetrika, YANDEX_METRIKA_GOALS, getPhoneText } from '@/utils'

const classes = {
  wrapper: cx(['flex', 'flex-col']),
  phone: cx([
    'mb-3',
    'xl:mb-[3px]',
    'text-base/[130%]',
    'xl:text-2xl/[130%]',
    'font-bold',
    'whitespace-nowrap',
  ]),
  time: cx([
    'inline-block',
    'mb-[18px]',
    'xl:mb-[16px]',
    'text-secondary-on-color',
    'text-sm/[120%]',
    'xl:text-base/[130%]',
  ]),
}
</script>