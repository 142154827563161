<template>
  <header
    id="header"
    :class="classes.header"
  >
    <div :class="classes.headerInner">
      <div
        v-if="!displayMobileSearchInput"
        class="container grid grid-cols-[100px_80px_92px] items-center justify-between xl:grid-cols-[192px_650px_auto]"
      >
        <BaseLogo />
        <WidgetLocation
          :city="currentCity"
          @click="openModalShowServiceCenters"
        />
        <div class="grid grid-flow-col gap-x-10 items-center">
          <BaseSearchInput
            v-if="!isDisabled"
            v-model="searchText"
            class="hidden xl:block xl:min-w-[415px] xl-xl:min-w-[445px] min-h-[44px]"
            placeholder="Искать iPhone 12"
          />
          <WidgetIconButtons @handle-click="toggleSearch" />
        </div>
      </div>
      <template v-else>
        <BaseSearchInput
          v-if="!isDisabled"
          v-model="searchText"
          class="container mx-auto"
          placeholder="Искать iPhone 12"
          closable
          @close="toggleSearch"
        />
      </template>
    </div>
    <WidgetMainMenu />
    <ModalsContainer />
  </header>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { ModalsContainer, useModal } from 'vue-final-modal'
import { storeToRefs } from 'pinia'
import { cx } from 'cva'
import ModalShowServiceCenter from '@/components/modal/ShowServiceCenter.vue'
import ModalShowServiceCenters from '@/components/modal/ShowServiceCenters.vue'
import ModalShowCities from '@/components/modal/ShowCities.vue'
import ModalConfirmCity from '@/components/modal/ConfirmCity.vue'

const classes = {
  header: cx(['sticky', 'top-0', 'z-[95]', 'h-[97px]', 'xl:h-[128px]']),
  headerInner: cx([
    'bg-bg-blue-accent',
    'xl:max-h-[90px]',
    'py-2.5',
    'xl:py-[22px]',
    'text-primary-on-color',
  ]),
}

const serviceCentersStore = useServiceCentersStore()
const citiesStore = useCitiesStore()
const searchStore = useSearchStore()

const { hasConfirm: hasConfirmCity, current: currentCity }
  = storeToRefs(citiesStore)
const { isDisabled } = storeToRefs(searchStore)
const searchText = ref('')
const displayMobileSearchInput = ref(false)

const toggleSearch = () => {
  displayMobileSearchInput.value = !displayMobileSearchInput.value
}

/* --------- MODALS START --------- */
const {
  open: openModalShowServiceCenters,
  close: closeModalShowServiceCenters,
} = useModal({
  component: ModalShowServiceCenters,
  attrs: {
    onServiceCenterSelected() {
      closeModalShowServiceCenters()
      openModalShowServiceCenter()
    },
    onShowCities() {
      closeModalShowServiceCenters()
      openModalShowCities()
    },
  },
})

const { open: openModalShowServiceCenter } = useModal({
  component: ModalShowServiceCenter,
})

const { open: openModalShowCities, close: closeModalShowCities } = useModal({
  component: ModalShowCities,
  attrs: {
    onChangeCity() {
      citiesStore.confirm()
      closeModalShowCities()
    },
    onClosed() {
      citiesStore.confirm()
    },
  },
})

const { open: openModalConfirmCity, close: closeModalConfirmCity } = useModal({
  component: ModalConfirmCity,
  attrs: {
    onConfirm() {
      citiesStore.confirm()
      closeModalConfirmCity()
    },
    onChangeCity() {
      closeModalConfirmCity()
      openModalShowCities()
    },
    onClosed() {
      citiesStore.confirm()
    },
  },
})
/* --------- MODALS END --------- */

onMounted(async () => {
  if (!hasConfirmCity.value) {
    openModalConfirmCity()
  }

  await useAsyncData('fetchServiceCenters', () =>
    serviceCentersStore.getList().then(() => true),
  )
})

watch(
  currentCity,
  async (newCurrentCity, oldCurrentCity) => {
    if (oldCurrentCity?.id !== newCurrentCity?.id) {
      await useAsyncData('fetchServiceCenters', () =>
        serviceCentersStore.getList().then(() => true),
      )
    }
  },
  { deep: true },
)
</script>