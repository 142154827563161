<template>
  <div v-if="seoText" ref="rootElement" :class="[sectionStyles, 'container']">
    <div
      :class="isCollapsed ? ['text-container', classes.text] : ''"
      v-html="seoText"
    ></div>
    <BaseButton intent="link" underline="on" @click.prevent="handleClick">
      {{ isCollapsed ? 'Развернуть' : 'Скрыть' }}
    </BaseButton>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { cx } from 'cva'
import { sectionStyles } from '@/utils'
import { ROUTE_NAMES } from '@/constants'

const rootElement = ref()

const classes = {
  text: cx([
    'relative',
    'max-h-28',
    'overflow-hidden',
    '[&>*]:block',
    '[&>*:nth-child(n+3)]:hidden',
    'xl:[&>*:nth-child(n+3)]:block',
    'mb-2.5',
    'before:absolute',
    'before:bottom-0',
    'before:w-full',
    'before:bg-gradient-to-b',
    'before:from-transparent',
    'before:to-white',
    'before:h-20',
  ]),
}

const route = useRoute()
const routeFullPath = computed(() => route.fullPath)
const seoContentStore = useSeoContentStore()

// загружаем SEO-контент
const currentRoute = useRoute()

await useAsyncData(
  'fetchSeoContent',
  async () => {
    const isNotCatalogPage = currentRoute.name !== ROUTE_NAMES.CATALOG_MODEL && currentRoute.name !== ROUTE_NAMES.CATALOG_MODEL_GROUP
    if (!isNotCatalogPage) return true

    seoContentStore.getList().then(() => true)
  },
  {
    watch: [routeFullPath],
  },
)

const isCollapsed = ref(true)
const seoText = computed(() => {
  return seoContentStore.list?.length ? seoContentStore.list[0].Text : ''
})

const scrollTo = () => {
  const header = document.getElementById('header')
  if (!header) return

  const headerHeight = header.offsetHeight
  const topOfElement
    = window.scrollY
    + rootElement.value.getBoundingClientRect().top
    - headerHeight
    - 20
  window.requestAnimationFrame(() => {
    window.scroll({ top: topOfElement, behavior: 'smooth' })
  })
}

const handleClick = () => {
  scrollTo()
  isCollapsed.value = !isCollapsed.value
}
</script>

<style scoped>
:deep(p) {
  margin-bottom: 16px;
}

:deep(h2) {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  :deep(h2) {
    font-size: 16px;
  }
}

:deep(ul) {
  margin-bottom: 16px;
}

:deep(li) {
  padding-left: 1em;
  text-indent: -0.7em;
}

:deep(li::before) {
  content: '• ';
  color: #023e7f;
}
</style>