<template>
  <div :class="classes.root" @click="clickOnBanner">
    <div :class="classes.wrapper">
      <div :class="classes.inner">
        <ul :class="classes.ul">
          <li :class="classes.li">
            <Icon
              name="common:point"
              size="5"
              class="text-icon-yellow"
            />
            Быстро
          </li>
          <li :class="classes.li">
            <Icon
              name="common:point"
              size="5"
              class="text-icon-yellow"
            />
            Дорого
          </li>
          <li :class="classes.li">
            <Icon
              name="common:point"
              size="5"
              class="text-icon-yellow"
            />
            Без коробки и документов
          </li>
        </ul>
        <div :class="classes.buttonWrapper">
          <BaseButton
            intent="base-primary"
            size="middle"
            :class="classes.button"
            style="border-radius: 8px"
          >
            Продать смартфон
          </BaseButton>
          <div :class="classes.iconWrapper">
            <Icon
              name="socials:telegram-white"
              size="16"
              class="ml-1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { computed } from 'vue'

const classes = {
  root: cx([
    'bg-gradient-banner',
    'cursor-pointer',
    'transition',
    'duration-300',
    'hover:opacity-90',
    'relative',
    'z-40',
  ]),
  wrapper: cx([
    `bg-[url('/static/images/banner/mob-l.png'),_url('/static/images/banner/mob-r.png')]`,
    `xl:bg-[url('/static/images/banner/desk-l.png'),_url('/static/images/banner/desk-r.png')]`,
    'bg-[position:left_bottom,_right_bottom]',
    'bg-no-repeat',
  ]),
  inner: cx([
    'container',
    'grid',
    'grid-rows-[repeat(2,auto)]',
    'xl:grid-rows-none',
    'xl:grid-cols-[repeat(2,min-content)]',
    'xl:justify-center',
    'xl:gap-[24px]',
    'place-items-center',
    'py-[10px]',
    'px-[16px]',
    'xl:py-[7px]',
  ]),
  ul: cx([
    'grid',
    'grid-cols-[repeat(3,min-content)]',
    'gap-[16px]',
    'mb-[6px]',
    'xl:mb-[0px]',
  ]),
  li: cx([
    'text-secondary-on-color',
    'whitespace-nowrap',
    'grid',
    'grid-cols-[5px_1fr]',
    'items-center',
    'gap-[7px]',
    'text-[11px]',
    'xxs:text-[14px]',
  ]),
  buttonWrapper: cx(['flex', 'items-center']),
  button: cx(['w-max', 'pt-[4px]', 'pb-[4px]']),
  iconWrapper: cx([
    'px-2',
    'py-1',
    'flex',
    'bg-snow-white-500',
    'rounded-r-lg',
    '-ml-2',
  ]),
}

const utmStore = useUtmStore()

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const telegramLink = computed(() => {
  if (!utmStore.utmTags) return ''

  const params = new URLSearchParams({
    start: `pm_${utmStore.utmTags['utm_campaign']}_${xlAndLarger.value ? 'desktop' : 'mobile'}`,
  })

  return `${getTelegramLink(2)}?${params.toString()}`
})

const clickOnBanner = () => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.BANNER_ABOVE_HEADER_CLICK)
  emitMixpanelEventBannerAboveHeaderClick()
  window.open(telegramLink.value, '_blank')
}
</script>