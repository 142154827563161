<template>
  <div v-on-click-outside="closeShowItems" class="relative cursor-pointer">
    <BaseButton
      :class="[
        'hover:text-accent-hover flex flex-row items-center gap-1.5',
        { 'flex-row-reverse': catalogIcon },
      ]"
      @click="toggleShowItems"
    >
      {{ name }}
      <Icon
        :name="catalogIcon ? 'common:catalog' : 'common:chevron'"
        size="8"
        :class="[
          '!text-primary ml-1.5 duration-300',
          { 'rotate-90': !catalogIcon && !isShowItems },
          { 'rotate-[270deg]': !catalogIcon && isShowItems },
        ]"
      />
    </BaseButton>
    <div
      v-show="isShowItems"
      :class="[classes.dropdownItems, { 'right-0': isRightPosition }]"
    >
      <BaseButton
        v-for="(item, index) in items"
        :key="index"
        type="nuxt-link"
        :class="[classes.dropdownItem({ disabled: item.disabled })]"
        :url="
          Object.values(ROUTE_NAMES).includes(item.to)
            ? { name: item.to }
            : item.to
        "
        :target="item.target ?? ''"
        @click="handleClick(item)"
      >
        {{ item.name }}
      </BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, type PropType } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { cva, cx } from 'cva'
import { ROUTE_NAMES } from '@/constants'

defineProps({
  name: {
    type: String,
    default: '',
    required: true,
  },
  items: {
    type: Array as PropType<Array<MenuItem>>,
    default() {
      return []
    },
    required: true,
  },
  catalogIcon: {
    type: Boolean,
    default: false,
  },
  isRightPosition: {
    type: Boolean,
    default: false,
  },
})

const classes = {
  dropdownItems: cx([
    'absolute',
    'top-[35px]',
    'flex',
    'flex-col',
    'py-[10px]',
    'rounded-xl',
    'bg-bg-main-primary',
    'transition',
    'duration-300',
  ]),
  dropdownItem: cva(
    [
      '!pt-[7px]',
      '!pb-[7px]',
      '!px-6',
      'border-b',
      'border-solid',
      'border-border-primary',
      '!text-base/[130%]',
      'last:border-0',
      'hover:bg-bg-main-thirdly',
      'transition',
      'duration-300',
    ],
    {
      variants: {
        disabled: {
          true: ['text-disable'],
        },
      },
    },
  ),
}

interface MenuItem {
  name: string
  to: string
  disabled?: boolean
  target?: string
  reachGoal: () => void
}

const isShowItems = ref(false)
const toggleShowItems = () => (isShowItems.value = !isShowItems.value)
const closeShowItems = () => (isShowItems.value = false)

const handleClick = (item: MenuItem) => {
  closeShowItems()
  item.reachGoal()
}
</script>