<template>
  <ul :class="classes.wrapper">
    <li v-for="(social, index) in socials" :key="index" @click="reachGoal">
      <ClientOnly>
        <a :href="social.href" target="_blank" :aria-label="social.ariaLabel">
          <Icon
            :name="social.icon"
            size="36"
            :class="cx(classes.icon)"
          />
        </a>
      </ClientOnly>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { shallowRef } from 'vue'
import { cx } from 'cva'
import { ROUTE_NAMES } from '@/constants'

const classes = {
  wrapper: cx(['grid', 'grid-flow-col', 'auto-cols-min', 'gap-x-[5px]']),
  icon: cx([
    'p-1',
    'rounded-lg',
    'text-primary-on-color',
    'duration-300',
    'hover:bg-icon-accent-hover',
  ]),
}

const route = useRoute()

const socials = shallowRef([
  {
    icon: 'socials:telegram',
    href: getTelegramLink(2) + '?start=pm_qr_desktop',
    ariaLabel: 'telegram',
  },
])

const reachGoal = () => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.SOCIAL_MEDIA_LINK_CLICK)

  if (route.name === ROUTE_NAMES.TRADE_IN) {
    emitYandexMetrika(YANDEX_METRIKA_GOALS.CONTACT_TI)
  }
}
</script>