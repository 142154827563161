<template>
  <div
    v-if="city"
    :class="classes.wrapper"
    @click="emitYandexMetrika(YANDEX_METRIKA_GOALS.CITY_HEADER)"
  >
    <Icon
      name="common:location"
      :size="xlAndLarger ? 24 : 18"
      :class="classes.icon"
    />
    <div :class="classes.content">
      <div :class="classes.name">
        {{ city?.name }} <span :class="classes.toggler">▼</span>
      </div>
      <div :class="classes.serviceCenters">
        {{ city.services_count }}
        {{ declension(city.services_count, ['филиал', 'филиала', 'филиалов']) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { cx } from 'cva'
import type { City } from '~/types/models'
import { declension, emitYandexMetrika, YANDEX_METRIKA_GOALS } from '@/utils'

defineProps({
  city: {
    type: Object as PropType<City | null>,
    default() {
      return {}
    },
    required: true,
  },
})

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const classes = {
  wrapper: cx([
    'flex',
    'items-center',
    'cursor-pointer',
    'duration-300',
    'hover:opacity-80',
  ]),
  icon: cx(['mr-2', 'xl:mr-4', 'p-px', 'hidden', 'xl:inline-block']),
  content: cx(['text-[10px]/[130%]', 'xl:text-base']),
  name: cx(['font-semibold', 'leading-[120%]', 'xl:leading-[130%]']),
  toggler: cx(['hidden', 'xl:inline', 'text-xs', 'font-bold']),
  serviceCenters: cx([
    'font-normal',
    'underline',
    'underline-offset-1',
    'opacity-80',
  ]),
}
</script>