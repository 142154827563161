<template>
  <p v-if="soldDevicesNumber" :class="classes.text">
    {{ soldDevicesNumber }}
    {{ declension(soldDevicesNumber, ['смартфон', 'смартфона', 'смартфонов']) }}
    в текущем каталоге
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { cx } from 'cva'
import { declension } from '@/utils'
import { ROUTE_NAMES } from '@/constants'

const classes = {
  text: cx([
    'text-[10px]/[120%]',
    'xl:text-[11px]/[130%]',
    'text-secondary-on-color',
    'opacity-40',
  ]),
}

const route = useRoute()
const catalogStore = useCatalogStore()
const devicesStore = useDevicesStore()

const isCatalogPage = computed(
  () =>
    route.name === ROUTE_NAMES.CATALOG_MODEL
    || route.name === ROUTE_NAMES.CATALOG_MODEL_GROUP,
)
const isCatalogCategoriesPage = computed(
  () => route.name === ROUTE_NAMES.CATALOG_CATEGORIES,
)

const soldDevicesNumber = computed(() => {
  if (isCatalogPage.value) return catalogStore.meta?.total
  else if (isCatalogCategoriesPage.value) return devicesStore.meta?.total

  return 0
})
</script>