<template>
  <div :class="classes.wrapper">
    <ul v-for="link in links" :key="link.name">
      <li :class="classes.category">
        {{ link.name }}
        <ul class="mt-4">
          <li
            v-for="(item, index) in link.items"
            :key="index"
            class="mb-3 last:mb-0"
          >
            <BaseButton
              :type="item.external ? 'a' : 'nuxt-link'"
              :href="item.external ? item.to : ''"
              :url="
                Object.values(ROUTE_NAMES).includes(item.to)
                  ? { name: item.to }
                  : item.to
              "
              :class="[
                classes.links,
                'group',
                item.disabled ? classes.unavailableLink : '',
              ]"
              :target="item.target ?? ''"
              @click="handleClick(item)"
            >
              <Icon
                v-if="item.name === 'Калькулятор Trade-In'"
                name="common:trade-in"
                size="20"
                class="-ml-[3px] mr-[2px] group-hover:animate-spin-left"
              />
              {{ item.name }}
            </BaseButton>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { cx } from 'cva'
import { MODEL_GROUP_NAME, ROUTE_NAMES } from '@/constants'

const classes = {
  wrapper: cx(['min-w-[728px]', 'mr-[102px]', 'flex', 'justify-between']),
  category: cx([
    'text-base/[130%]',
    'text-primary-on-color',
    'font-bold',
    'whitespace-nowrap',
  ]),
  links: cx([
    'flex',
    'items-center',
    'text-base/[130%]',
    'text-secondary-on-color',
    'hover:text-primary-on-color',
    'font-normal',
    'duration-300',
  ]),
  unavailableLink: cx(['opacity-60', 'pointer-events-none']),
}

const otherLinks = [
  {
    name: 'О нас',
    items: [
      {
        name: 'Контакты',
        to: ROUTE_NAMES.CONTACTS,
        external: false,
        disabled: false,
      },
      {
        name: 'О компании',
        to: ROUTE_NAMES.COMPANY,
        external: false,
        disabled: false,
      },
      {
        name: 'Публичная оферта',
        to: '/ofertaPM',
        external: false,
        disabled: false,
        target: '_blank',
      },
      {
        name: 'Договор (оферта) аренды оборудования',
        to: '/oferta_podpiska',
        external: false,
        disabled: false,
        target: '_blank',
      },
      {
        name: 'Реквизиты компании',
        to: ROUTE_NAMES.COMPANY_DETAILS,
        external: false,
        disabled: false,
      },
      {
        name: 'Политика по обработке персональных данных',
        to: '/politics',
        external: false,
        disabled: false,
        target: '_blank',
      },
      {
        name: 'Правила гарантийного обслуживания',
        to: ROUTE_NAMES.GARANTIA,
        external: false,
        disabled: false,
      },
      {
        name: 'Адреса СЦ для гарантийного ремонта',
        to: ROUTE_NAMES.GARANTIA_SERVICE,
        external: false,
        disabled: false,
      },
    ],
  },
  {
    name: 'Покупателям',
    items: [
      {
        name: 'Калькулятор Trade-In',
        to: ROUTE_NAMES.TRADE_IN,
        external: false,
        disabled: false,
      },
      {
        name: 'Каталог товаров',
        to: ROUTE_NAMES.CATALOG_CATEGORIES,
        external: false,
        disabled: false,
      },
      {
        name: 'Возврат',
        to: ROUTE_NAMES.RETURN,
        external: false,
        disabled: false,
      },
      {
        name: 'Гарантия',
        to: ROUTE_NAMES.GUARANTEE,
        external: false,
        disabled: false,
      },
      {
        name: 'Способы оплаты',
        to: ROUTE_NAMES.PAYMENT,
        external: false,
        disabled: false,
      },
      {
        name: 'Личный кабинет',
        to: 'https://lk.pedant.market',
        external: true,
        disabled: false,
      },
      {
        name: 'Отмена подписки',
        to: 'https://lk.pedant.market',
        external: true,
        disabled: false,
      },
    ],
  },
]

interface FooterMainMenuItem {
  name: string
  to: string
  external: boolean
  disabled: boolean
  target?: string
}

const modelGroupsStore = useModelGroupsStore()
const { list: categories } = storeToRefs(modelGroupsStore)

const links = computed(() => {
  const catalogItems = categories.value?.map((category) => {
    return {
      name: category.name,
      to: category.url,
      external: false,
      disabled: !category.models.length,
    } as FooterMainMenuItem
  })

  return [
    {
      name: 'Каталог товаров',
      items: catalogItems,
    },
    ...otherLinks,
  ]
})

const handleClick = (item: FooterMainMenuItem) => {
  item.name === MODEL_GROUP_NAME.IPHONE
  && (emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_IPHONE),
  emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_IPHONE_IZ_FOOTERA))
}
</script>