<template>
  <div :class="classes.wrapper">
    <a :class="classes.link" aria-current="page" href="/">
      <img
        :class="classes.logo"
        alt="Pedant.Market"
        src="/static/images/logo.svg"
        width="192"
        height="24"
      >
      <div :class="[classes.text, textClassName]">Смартфоны по подписке</div>
    </a>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'

defineProps({
  textClassName: {
    type: String,
    default: '',
  },
})

const classes = {
  wrapper: cx([
    'w-[100px]',
    'h-[25px]',
    'xl:w-[192px]',
    'xl:h-[48px]',
    'flex',
    'items-center',
    'overflow-hidden',
  ]),
  link: cx([
    'duration-300',
    'flex',
    'flex-col',
    'hover:opacity-80',
    'no-underline',
    'outline-0',
    'cursor-pointer',
    'text-primary-on-color',
  ]),
  logo: cx(['align-middle', 'mb-[2px]', 'xl:mb-1']),
  text: cx([
    'font-proxima-nova',
    'font-semibold',
    'text-[8.3px]/[11px]',
    'xl:text-base/[130%]',
    'xl:leading-[18px]',
    'tracking-[.19px]',
    'xl:tracking-[.35px]',
  ]),
}
</script>