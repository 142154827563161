<!-- eslint-disable no-unused-vars -->
<template>
  <BaseModal
    v-if="currentCity"
    :to-bottom="!xlAndLarger"
    @closed="emit('closed')"
  >
    <div
      class="min-w-max p-4 xl:min-w-[428px] pr-[30px] pb-5 grid auto-cols-fr justify-items-center"
    >
      <Icon
        name="common:location"
        size="54"
        class="!text-accent mb-4"
      />
      <p class="text-center mb-2.5">
        Ваш город
        <span class="inline-block w-full text-2xl/[36px] font-bold">
          {{ currentCity.name }}?
        </span>
      </p>
      <div class="grid gap-x-3 grid-cols-2 w-full">
        <BaseButton
          intent="base-primary"
          size="middle"
          @click="emit('confirm')"
        >
          Да
        </BaseButton>
        <BaseButton
          intent="base-secondary"
          size="middle"
          @click="emit('changeCity')"
        >
          Выбрать другой
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

const emit = defineEmits<{
  (e: 'confirm' | 'changeCity' | 'closed'): void
}>()

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const citiesStore = useCitiesStore()
const { current: currentCity } = storeToRefs(citiesStore)
</script>